@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700;800;900&display=swap");
@import url(https://db.onlinewebfonts.com/c/eedf6065ba5bd736bc615b48e6c9ba75?family=FranziskaWeb+W03+Bold);

/* @font-face {
  font-family: "FranziskaWeb W03 Bold";
  src: url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.eot");
  src: url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/eedf6065ba5bd736bc615b48e6c9ba75.svg#FranziskaWeb W03 Bold")format("svg");
} */

@font-face {
  font-family: 'FranziskaWeb W03 bold';
  src: url('./fonts/FranziskaWeb\ W03\ Bold.ttf')format('truetype');
}

@font-face {
  font-family: 'FranziskaWeb W03';
  src: url('./fonts/FranziskaWeb\ W03\ Light.ttf')format('truetype');
}

@media print {
  body {print-color-adjust: exact;}
}

body {
  font-family: 'Oswald', sans-serif;
  height: 100%;
  width: 100%;
}

/*================================================================================
              PERSO
  ==============================================================================*/

.fs-smaller {
  font-size: smaller;
}

/* .zone-drop{
  height: 200px;
} */

.drop-zone {
  border: 1px solid black;
  padding: 30px;
  text-align: center;
  cursor: pointer;
}

.drop-zone.dragging {
  background-color: #f9f9f9;
  border-color: #999;
}

@keyframes slide {
  from {
    transform: translateX(-30%);
  }

  to {
    transform: translateX(100%);
  }
}


.slide {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  display: inline-block;
}

.slide-img {
  width: 100%;
  animation: 5s slide infinite linear;

}

.slide-img img {
  width: 150px;
}

.slide-logo {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  display: inline-block;
}

/* .slide-logo::after {
  background: -webkit-gradient(linear, right top, left top, from(white), to(rgba(255, 255, 255, 0)));
	background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
	height: 175px;
	position: absolute;
	width: 200px;
	z-index: 2;
  right: 0;
  top: 0;
}
.slide-logo::before {
  background: -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
	background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: 175px;
	position: absolute;
	width: 200px;
	z-index: 2;
  top: 1;
  left: 0;
} */
.slide-logo-img {
  width: 100%;
  animation: 10s slide infinite linear;
  left: 0;
  top: 2;

}

.slide-logo-img img {
  width: 200px;
  margin-right: 25px;
}

/*pricing cards1*/

.card {
  position: relative;
  /* max-width: 300px; */
  height: auto;
  background: linear-gradient(-50deg, #048508, #fb9302);
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  -webkit-transition: .5s;
  transition: .5s;
}

.card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.col-sm-4:nth-child(1) .card,
.col-sm-4:nth-child(1) .card .title .fa {
  background: linear-gradient(-45deg, #f403d1, #64b5f6);

}

.col-sm-4:nth-child(2) .card,
.col-sm-4:nth-child(2) .card .title .fa {
  background: linear-gradient(-45deg, #ffec61, #f321d7);

}

.col-sm-4:nth-child(3) .card,
.col-sm-4:nth-child(3) .card .title .fa {
  background: linear-gradient(-45deg, #24ff72, #9a4eff);

}

.card::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: rgba(255, 255, 255, .1);
  z-index: -1;
  -webkit-transform: skewY(-5deg) scale(1.5);
  transform: skewY(-5deg) scale(1.5);
}

.title .fa {
  color: #fff;
  font-size: 40px;
  width: 100px;
  margin-right: 7px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, .1);

}

.title h2 {
  position: relative;
  margin: 20px 0 0;
  padding: 0;
  color: #fff;
  font-size: 25px;
  z-index: 2;
}

.price,
.option {
  position: relative;
  z-index: 2;
}

.price h4 {
  margin: 0;
  padding: 20px 0;
  color: #fff;
  font-size: 40px;
}

.option ul {
  margin: 0;
  padding: 0;

}

.option ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  color: #fff;
  font-size: 14px;
}

.card button {
  position: relative;
  z-index: 2;
  background: #fff;
  color: black;
  width: 150px;
  /* height: 40px; */
  line-height: 40px;
  border-radius: 40px;
  display: block;
  text-align: center;
  margin: 20px auto 0;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

}

.card a:hover {
  text-decoration: none;
}

/*pricing cards2*/

.main__heading {
  font-weight: 600;
  font-size: 2.25em;
  margin-bottom: 0.75em;
  text-align: center;
  color: #000000;
}

.cards {
  position: relative;
}

.cards__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5em;
}

.card {
  --flow-space: 0.5em;
  --hsl: var(--hue), var(--saturation), var(--lightness);
  background: linear-gradient(-50deg, #048508, #fb9302);
  width: 30%;
  flex: 1 1 14rem;
  padding: 1.5em 2em;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
  gap: 1.25em;
  margin: 25px;
  color: #eceff1;
  background-color: #2b2b2b;
  border: 1px solid #eceff133;
  border-radius: 15px;
  font-family: "League Spartan", system-ui, sans-serif;
  transform: .5s;
  -webkit-transition: .5s;
}



/* .fa{
  color:#fff;
  font-size: 40px;
  width: 100px;
  margin-right: 7px;
  height: 100px;
  text-align: center;
  line-height: 100px;
} */
.card:nth-child(1) {
  --hue: 165;
  --saturation: 82.26%;
  --lightness: 51.37%;
}

.card:nth-child(2) {
  --hue: 291.34;
  --saturation: 95.9%;
  --lightness: 61.76%;
}

.card:nth-child(3) {
  --hue: 338.69;
  --saturation: 100%;
  --lightness: 48.04%;
}

.card__bullets {
  line-height: 1.4;
}

.card__bullets li::before {
  display: inline-block;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='16' title='check' fill='%23dddddd'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' /%3E%3C/svg%3E");
  transform: translatey(0.25ch);
  margin-right: 1ch;
}

.card__heading {
  font-size: 1.05em;
  font-weight: 600;
}

.card__price {
  font-size: 1.75em;
  font-weight: 700;
}

.card__containImg {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 1px solid transparent;
  padding: 0.4rem;
}

.card__containImg div {
  height: 100%;
  width: 100%;
}

.card__containImg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.flow>*+* {
  margin-top: var(--flow-space, 1.25em);
}

.cta {
  display: block;
  align-self: end;
  margin: 1em 0 0.5em 0;
  text-align: center;
  text-decoration: none;
  color: 000;
  background-color: #fff;
  padding: 0.7em;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
}

.overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--opacity, 0);
  -webkit-mask: radial-gradient(25rem 25rem at var(--x) var(--y),
      #000 1%,
      transparent 50%);
  mask: radial-gradient(25rem 25rem at var(--x) var(--y),
      #000 1%,
      transparent 50%);
  transition: 400ms mask ease;
  will-change: mask;
}

.overlay .card {
  background-color: hsla(var(--hsl), 0.15);
  border-color: hsla(var(--hsl), 1);
  box-shadow: 0 0 0 1px inset hsl(var(--hsl));
}

.overlay .cta {
  display: block;
  grid-row: -1;
  width: 100%;
  background-color: hsl(var(--hsl));
  box-shadow: 0 0 0 1px hsl(var(--hsl));
}

:not(.overlay)>.card {
  transition: 400ms background ease;
  will-change: background;
}

:not(.overlay)>.card .button:hover {
  --lightness: 95%;
  background: hsla(var(--hsl), 0.1);
}

.forfait-header {
  background-color: #72e47a;
  border-radius: 10px;
  height: 130px;

}

.forfait-body {
  font-size: 0.6rem;
  border-radius: 10px;
  border: 1px solid rgb(214, 213, 213);
  background-color: white;
  z-index: 30;
  margin-top: -10px;

}

.forfait-body p {
  margin: 5px 0;
}

.forfait-btn-select {
  background-color: #e28301;
  border: none;
}

.forfait2 .forfait-header {
  background-color: #2c6930;
}

.forfait3 .forfait-header {
  background-color: rgb(73, 73, 73);
}

.bande {
  height: 3px;
  width: 100%;
  background-color: #e28301;
}

.bg-yellow {
  background-color: #e28301;
}

.text-yellow {
  color: #e28301;
}

.bg-green {
  background-color: #2c6930;
}

.text-green {
  color: #2c6930;
}

/*================================================================================
              BANNER
  ==============================================================================*/
.info-banner {
  /* background-color: #008f58; */

  background: rgb(245, 127, 0);
  background: linear-gradient(90deg, rgba(245, 127, 0, 1) 0%, rgba(255, 255, 255, 1) 50%, #008f58 100%);
}

.info-banner .container {
  padding: 0px auto;

}

.info-banner p {
  min-height: 100%;
}

.messagedefilant {
  /* display: block;
  margin: 40px auto; 
  padding: 0;  */
  overflow: hidden;
  /* position: relative;
  width: 50%;
  max-width: 640px;
  height: 60px; */
}

/* .messagedefilant div {
  position: absolute;
  min-width: 100%; au minimum la largeur du conteneur
} */

.messagedefilant div span,
.messagedefilant div:after {

  /* font-size: 2rem; */
  position: relative;
  display: inline-block;
  white-space: nowrap;
  top: 0;
}

.messagedefilant div span {

  animation: defilement 20s infinite linear;
  /* background: #cde; */
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@keyframes defilement {
  0% {
    margin-left: 100%;
  }

  100% {
    margin-left: -100%;
  }
}

/*================================================================================
              NAVBAR
  ==============================================================================*/
.navbar,
navbar-nav,
navbar-expand-lg {
  background-color: white !important;
  padding: 1px !important;
  /* font-family: 'Oswald', sans-serif; */
}

.navbar-brand:hover {
  cursor: pointer;
}

.navbar li,
.navbar a {
  cursor: default;
}


.nav-btn-rdv {
  background-color: #e28301;
  color: gray !important;
}

.nav-btn-rdv:hover,
.nav-btn-connexion:hover {
  cursor: pointer;
}

.nav-btn-connexion {
  background-color: #2c6930;
  color: white !important;
}

.navbar-collapse {
  justify-content: end !important;
}


/*================================================================================
              NAVBAR alter
  ==============================================================================*/

.navAlter {
  background-color: #c8f3e5 !important;
}

.dropdown {

  display: inline-block;

}

.dropdown-menu {
  display: block;
  border: none;
  margin-top: 0;
  top: 150%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: .5s;
}

.dropdown-menu .dropdown-item {
  width: 100%;
}

.nav-item:hover .dropdown-menu {
  top: 100%;
  visibility: visible;
  transition: .5s;
  opacity: 1;
}

.dropdown-toggle {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.dropdown-item {
  top: 0;
  right: 1.5rem;
  width: 100px;
  border-radius: #fff;
  display: none;
  padding: 15px;
  background-color: #fff;

}

.dropdown:hover .dropdown-item {
  display: block;
}

/*================================================================================
              INPUT
  ==============================================================================*/
.form-style input,
select {
  border-radius: 8px;
  border: .5px solid rgb(0, 119, 255);
}

.form-check-label {
  font-size: 0.7rem;
}

.radio {
  width: 25px;
  height: 25px;
  border-radius: 50% !important;

}

.radio-m.radio:checked {
  background-color: rgb(0, 119, 255);
}

.radio-f.radio:checked {
  background-color: rgb(255, 0, 157);
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}




/*================================================================================
              Liste
  ==============================================================================*/

.list-typeActe,
.list-ACD {
  list-style: none;

}

ol li {
  list-style: decimal;
  font-weight: bolder;
}

.list-typeActe li::before,
.list-ACD li::before {
  content: "-";
  /* Utilise un tiret comme marqueur avant chaque élément de la liste */
  margin-right: 0.5em;
  /* Ajoute un espacement entre le tiret et le texte de l'élément */
}

/* .list-typeActe li:last-child{
  font-weight: bolder ;
} */

.notaBene,
.liste-imbriquee {
  list-style: square;

}

.liste-imbriquee li::before {
  content: none;
}

ol ul.list-typeActe li {
  list-style: none;
  font-weight: lighter;
}







/*================================================================================
              LIENS
  ==============================================================================*/
/* .lien-ambassypay{
  text-decoration: none;
} */


/*================================================================================
              Footer
  ==============================================================================*/

.footer {
  background-color: rgb(73, 73, 73);
}

.footer-main {
  background: linear-gradient(rgba(0, 0, 0, .40), rgba(0, 0, 0, .40)), url(/public/images/mairie_attecoube.jpg);
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}


/*================================================================================
              Home page
  ==============================================================================*/

.home-section-1 {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, .50), rgba(0, 0, 0, .50)), url(/public/images/new_guichet2.jpg) center center no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Oswald', sans-serif;
}



.pulse-button:hover {
  -webkit-animation: none;
}

button.pulse-button {
  border-radius: 1rem;
  background-color: #3b8c7d;
  color: white;
}

@keyframes colorBackground {
  0% {
    background-color: #3b8c7d;
  }

  50% {
    background-color: white;
    color: #000
  }

  100% {
    background-color: #3b8c7d;
  }
}

@-webkit-keyframes pulse {
  0% {
    @include transform(scale(.9));
  }

  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#fb8d1f, 0);
  }

  100% {
    @include transform(scale(.9));
    box-shadow: 0 0 0 0 rgba(#fb8d1f, 0);
  }
}

.btn-demarche {
  border: none;
  text-wrap: wrap;
  padding: 5px;
  width: 200px;
  height: 100%;
  background-color: transparent;
  color: white;
  border-radius: 5px !important;
  margin: 8px;
  animation: colorBackground 3s infinite;
}

.btn-actes {

  background-color: #3b8c7d;
}

.btn-actes div {
  min-height: '200px';
  max-width: '200px';
}

.btn-actes div img {
  width: 150px;
}

.btn-demarche i {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 7px;
  color: #fb8d1f;
}

.btn-demarche:hover,
.btn-demarche2:hover {
  background-color: #3b8c7d;
  color: #ffffff !important;
  border-radius: 3px;
}


.btn-plus {
  background-color: #3b8c7d;
  color: white;
  outline: none;
}

.home-section-3 {
  /* background-image: url('/public/images/ville_nuit.jpg'); */
  background-position: center;
  background-size: cover;
  min-height: 48vh;
  font-family: "FranziskaWeb W03";
}

.home-section-3 h2 {
  font-family: "FranziskaWeb W03 bold";
  /* font-size: 3.5rem !important; */
}

.btn-demarche2 {
  border: 1px solid #3b8c7d;
  text-wrap: wrap;
  padding: 0px;
  width: 250px;
  height: 50px;
  /* background-color: #e28301; */
  background-color: transparent;
  color: white;
  font-size: 0.8rem;
}

.detail-section {
  background-image: linear-gradient(rgba(0, 0, 0, .20), rgba(0, 0, 0, .20)), url('/public/images/documents.webp');
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  min-height: 55vh;
  font-family: "FranziskaWeb W03";

}

.detail-section h2 {
  font-family: "FranziskaWeb W03 bold";
  /* font-size: 3.5rem !important; */
}

.btn-experience {
  border: none;
  background-color: rgb(73, 73, 73);
}

.btn-experience {
  text-transform: uppercase;
}

/*================================================================================
              AVISPAGE
  ==============================================================================*/
.bloc-avis {
  background-color: #eae8e8;
  /* background-color: #999; */
  border-radius: 30px;
}

.bloc-avis input {
  border-radius: 35px;
  background-color: #b1afaf;

}

.bloc-avis input:focus {
  border-color: #e28301;
  background-color: #b1afaf;
}

.note-number {
  background-color: #b1afaf;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid #b1afaf;

}

.bloc-avis textarea {
  background-color: #b1afaf;
  border-radius: 20px;
}

.bloc-avis textarea:focus {
  border-color: #e28301;
  background-color: #b1afaf;
}

.note-active {
  background-color: #e58b1d;
  border: 1px solid #e58b1d;

}

.note-number:hover {
  border: 1px solid #e58b1d;
}

.btn-avis {
  background-color: #e28301;

}

.btn-avis:hover {
  background-color: #e28301;

}

.msge-sent {
  min-height: 70vh;
}

/*================================================================================
              LOGINPAGE
  ==============================================================================*/

.loginPage {
  /* background-image: url('/public/images/login-fond3.jpg'); */
  background-position: center;
  background-size: cover;
  min-height: 90vh;
}


.nouvelle-demande,
.loginFormDiv {
  background-color: white;
  border-radius: 20px;
}

.nouvelle-demande h2,
.loginFormDiv h2 {
  margin-top: -45px;
}

.loginFormDiv {
  margin-top: 30px;
}

.btn-login {
  background-color: #e28301;
  border: none;
  color: white;
}

/*============ Loader ============*/
.loader {
  border: 10px solid transparent;
  border-top: 10px solid #3b8c7d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*============ Close Loader ============*/


/*================================================================================
              RESPONSIVE
  ==============================================================================*/

@media (max-width: 767.98px) {
  .footer-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
  }

  .icone-demarche {
    font-size: 5rem;
  }

  .forfait-type-container,
  .type-acces-container,
  forfait-container,
  forfait-descript-conatainer {
    flex-wrap: wrap;
  }

  .bande {
    display: none;
  }

  .radio-choix-demandeur {
    display: block !important;
  }

  .block-btn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper-content-phone {
    flex-wrap: wrap;
  }


  .btn-demarche {
    padding: 0px;
    width: 260px;
    height: 50px;
  }

  .app-title {
    font-size: 25px;
  }

}

.app-title {
  font-size: 25px;
}

.div-block-16 {
  height: 40px;
  background-color: #e58b1d;
  justify-content: space-between;
  display: flex;
}

.div-block-17 {
  width: 150px;
  height: 40px;
  text-align: center;
  background-color: #000;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  padding-left: 0;
  font-family: Merriweather, serif;
  display: flex;
}

.text-block-2 {
  color: #f5f5f5;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.loop-text-outer {
  width: 100%;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
}

.loop-text-inner {
  align-items: center;
  display: flex;
}

.banner {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-4 {
  color: #fff;
  margin-left: -374px;
  font-family: Merriweather, serif;
  font-size: 18px;
  display: none;
}

.div-block-18 {
  width: auto;
  height: 40px;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: center;
  margin-right: 0;
  padding-right: 10px;
  display: flex;
}

.html-embed-9 {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding: 0 10px;
}

.heading-39._1,
.heading-39._2 {
  display: block;
}

.text-block-3 {
  padding-left: 2px;
  padding-right: 5px;
  font-family: Oswald, sans-serif;
  font-size: 18px;
  line-height: 20px;
}

.heading-39 {
  color: #3f1201;
  white-space: nowrap;
  padding-left: 10px;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

@media screen and (max-width: 991px) {
  .div-block-16 {
    justify-content: flex-end;
    align-items: center;
  }

  .div-block-17 {
    flex: 0 auto;
    align-self: auto;
    align-items: center;
    margin-left: 20px;
    display: flex;
  }

  .text-block-2 {
    width: 100px;
  }

  .banner {
    display: block;
  }

  .div-block-18 {
    width: auto;
    flex: 1;
    justify-content: flex-end;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .div-block-16 {
    justify-content: center;
    align-items: stretch;
  }

  .text-block-2 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 479px) {
  .div-block-16 {
    width: auto;
    height: 40px;
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
  }

  .div-block-17 {
    width: auto;
    display: flex;
  }

  .loop-text-outer {
    display: block;
  }

  .banner {
    display: block;
  }

  .div-block-18 {
    width: auto;
    flex: none;
    order: 1;
    justify-content: space-between;
    align-self: flex-start;
    padding-right: 5px;
    font-family: Oswald, sans-serif;
    display: flex;
  }

  .html-embed-9 {
    justify-content: flex-end;
    margin-left: 0;
    font-size: 12px;
    display: block;
  }

  .text-block-3 {
    font-size: 16px;
  }
}


/* section container 1 */

.section1-container {
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #3b8c7d;

  padding: 25px 25px;
}

.section1-container h1 {
  border: 5px solid #3b8c7d;
  padding: 15px 15px;
  font-size: 55px;
  background-color: #ffffffad;

}

@media (max-width: 576px) {
  .section1-container {
    width: 100%;
    margin-top: 170px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .info-flash {
    margin-left: -30px !important;
    padding: 15px;
  }

  .home-slide {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .section1-container {
    width: 50%;
    margin-top: 170px;
    margin-bottom: 50px;
  }

  .info-flash {
    padding: 15px;
    margin-left: -30px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .section1-container {
    width: 80%;
    margin-top: 100px;
    margin-bottom: 70px;
  }

  .info-flash {
    padding: 15px;
    margin-left: -30px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .section1-container {
    width: 85%;
  }

  .info-flash {
    margin-left: 10px !important;
    padding: 0px;
  }
}

/* .last{
  margin-bottom: 100px !important;
} */
/* DASHBORD MENU BOX  */

.flex-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.menu-box {
  margin: 15px;
  width: 30% !important;
}

.menu-box-menu {
  margin: 0 !important;
  padding: 0 !important;
}

.menu-box-menu .icon {
  vertical-align: top;
  width: 28px;


}

.menu-box-number {
  width: 30px;
  height: 30px;
  color: #fff;
  background: #00b1a5;
  text-align: center;
  border-radius: 15px;
  border: none;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.menu-box-tab {

  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 0;
  margin-bottom: 5px;
  text-decoration: none;
  color: #000;
  border: 1px solid #1f253d;
  border-radius: 8px;
  -webkit-transition: background .2s;
  transition: background .2s;
  background-color: #fff;
}

.menu-box-tab i {
  color: #02705a;
}

.menu-box-tab:hover {
  background: #029a7b;
  border-top: 1px solid #1f253d;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.menu-box-tab:hover .icon {
  color: #fff;
}

/* .menu-box-tab:hover .menu-box-number {
      background: #015443;
    } */

.scroll-x {
  overflow-x: auto;
  width: auto;

}

@media (max-width: 1070px) {
  .menu-box {
    width: 80% !important;
  }
}

@media (max-width: 550px) {
  .menu-box {
    width: 90% !important;
  }
}

iframe {
  width: 100%;
  height: 100%;
}

.btn-profile-update {
  background-color: #00b1a5 !important;
  border-color: #00b1a5;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  ;
}

.checkbox-custom {
  width: 200%;
  height: 200%;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Oswald', sans-serif !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root input {
  border: transparent !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Oswald', sans-serif !important;
}

.css-1oz3td-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.form-custom-select {
  padding: 15px;
}

.notification-containgImg-spinner {
  height: 7rem;
}

.notification-containgImg-spinner img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.download-doc {
  height: 36px;
  padding: 2px;
  border-radius: 36px;
  background: #00b1a5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* grid-gap: 10px; */
  font-weight: 500;
  transition: .2s;
  margin-bottom: 5px;
}

.download-doc-gray {
  height: 36px;
  padding: 2px;
  border-radius: 36px;
  background: #7d7d7d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* grid-gap: 10px; */
  font-weight: 500;
  transition: .2s;
  margin-bottom: 5px;
}

.download-doc:hover {
  scale: 1.04;
  background: #026f68;
  
}
.download-doc-gray:hover {
  scale: 1.04;
  background: #545454;
  cursor: pointer;
  
}
.download-doc a {
  color: #fff;
  text-decoration: none;
  
}
.download-doc bx {
  color:#fff;
  text-decoration: none;
  width: 50px !important;
  
}

.bg-receipt-blank{
  background-color: #efefef;
}

.bg-receipt-content{
  background-color: white;
}

.recu-containDivImg{
  height: 4rem;
}

.recu-containDivImg-containImg{
  height: 100%;
}

.recu-containDivImg-containImg img{
  height: 100%;
  object-fit: contain;
}

.recu-notification{
  color: white;
  border-radius: 1rem;
  width: max-content;
}

.info-big-toPayment table td,
.info-big-toPayment table th {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-big-toPayment tbody td {
  font-weight: 500;
  color: #999999;
}

.info-big-toPayment{
  border-radius: 1rem;
}

.gradient-custom-1 {
  /* fallback for old browsers */
  background: #cd9cf2;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to top, rgba(205, 156, 242, 1), rgba(246, 243, 255, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to top, rgba(205, 156, 242, 1), rgba(246, 243, 255, 1))
}

.disabledMarginBottom{
  margin-bottom: 0 !important ;
}

.receipt-containDivCodeQR{
  height: 5rem;
}

.receipt-containDivCodeQR-containCodeQR{
  height: 100%;
}

.footer-receipt{
  background: #000000;
  width: 100%;
  color : white;
  border-top: 1px solid #ddd;
}

.custom-bg{
  background-color: white;
}