.login_page{
  height: 80%;
}
.login-img {
  display: none;
  width: 40%;
}

.login-form {
  height: 90vh;
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#company-name {
  display: none;
}

.login-form h1 {
  color: #2c6930;
  font-size: 5rem;
  padding-bottom: 20px;
}

.login-form p {
  font-size: 1.8rem;
}

.login-form h3 {
  padding-bottom: 10px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0;
}

.form > * {
  flex: 100%;
}

.login-input {
  border:1px solid #dcdde1;
  margin-bottom: 15px;
}

.login-input:focus {
  outline: none;
  border-color: rgba(6, 145, 4, 0.4);
  box-shadow: 0 0 4px rgba(6, 145, 4, 0.4);
}

.login-label {
  margin-bottom: 5px;
}

.login-input, login-button {
  border-radius: 5px;
  padding: 15px;
}

.login-button {
  background-color: #2c6930;
  border: none;
  padding: 8px;
  color: #f5f6fa;
  border-radius:8px ;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: background-color linear .3s;
}

.login-button:active,
.login-button:hover {
  background-color: #2c6930;
}

.text-info {
  text-align: center;
}

.text-info a,
.text-info p {
  font-size: 1.5rem;
}

.text-info p {
  padding-top: 10px;
}

.text-info a {
  color: #2c6930;
  text-decoration: none;
  transition: color ease-in .3s;
}

.text-info a:active,
.text-info a:hover {
  color: #353b48;
}

/* media queries */
@media screen and (min-width:990px) {
  .login-img,
  .login-form {
      padding: 5%;
  }
  .login-form #company-name {
      display: inline;
  }

  .login-form h1 {
      font-size: 3rem;
  }

  .login-form i {
      font-size: 2.5rem;
      vertical-align: super;
  }

  .login-img {
      background: linear-gradient(to bottom, rgba(16, 154, 1, 0.8), rgba(36, 11, 54, 0.8)),
      url(/public/images/login.jpg);
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
  }

  .login-img h1,
  .login-img p {
      width: 300px;
  }

  .login-img h1 {
      color: #f5f6fa;
      font-size: 3.5rem;
  }

  .login-img p {
      color: #bebebe;
      line-height: 1.5;
  }
}