@import url('https://byjaris.com/code/fonts/poppins.css');
@import url('https://byjaris.com/code/fonts/roboto.css');

html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  /* font-family: Poppins; */
  margin: 0;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 260px;
  background: #3b8c7d;
  padding: 6px 14px;
  box-sizing: border-box;  
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open {
  width: 78px; 
  transition: all 0.5s ease;
}
.sidebar .logo-details {
  /* height: 60px; */
  margin-top: 15px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 1;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  transition: all 0.6s ease-in;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 0;
  transition: all 0.3s ease-in;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: center;
}
.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  /* margin-top: 20px; */
  padding-left: 0;
  height: 100%;
}

.sidebar .nav-list {
  left: -300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;  
/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar .nav-list::-webkit-scrollbar {
  display: none;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 9;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  box-sizing: border-box;  
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
  display: none;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: block;
}
.sidebar input {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 12px;
  transition: all 0.1s ease;
  background: #a35f00;
  padding: 0 15px 0 60px;
  box-sizing: border-box;  
}
.sidebar.open input {
  width: 50px;
  padding: 0;
  transition: all 1.2s ease;
}
.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #c27203;
  color: #FFF;
}
.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #FFF;
}
.sidebar .bx-search:hover {
  background: #FFF;
  color: #11101d;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #1a6053;
}
.sidebar li:not(:last-child) a:hover {
  background: #FFF;
}
.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 1;
  pointer-events: none;
  transition: all ease-in 0.3s;
}
.sidebar.open li a .links_name {
  opacity: 0;
  pointer-events: auto;
  transition: all ease-in 0.3s;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101D;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 260px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  box-sizing: border-box;  
  background: #1a6053;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile {
  width: 78px;
}
.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #0d4339;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 78px;
  background: none;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #c27203;
  max-width: 100%;  
  transition: all 0.5s ease;
}
.home-section {
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}
/* @media(max-width:700px){
  .sidebar{
    width: 100% !important;
    position: fixed;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100px;

  }
  .sidebar .logo-details{
    display: none;
  }
  .sidebar .nav-list{
    display: flex;
    justify-content: space-evenly;
    margin: 0 !important;
   width: 100%;
  }
  .sidebar .nav-list li {
    width: 75px;
    height: 75px;
    padding: 0;
    transition: all 1.2s ease;
  }
  .sidebar .nav-list li i {
    
    line-height:-1.5 ;
  }
  .sidebar .nav-list li a{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sidebar .nav-list .links_name {
    
  }
  .home-section{
    width: 100% !important;
    left: auto !important;
  }
  .sidebar li.profile{
    left: auto ;
    right: 0 !important;
    top: 0 !important;
  }
} */
@media (max-width: 778px) {
   .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
}
.home-section .text {
  display: inline-block;
  color: #c27203;
  font-size: 25px;
  font-weight: 500;
  padding: 36px 36px 0;
  box-sizing: border-box;  
}
@media (max-width: 420px) {
  .sidebar li .tooltip{
    display: none;
  }
}

/*
*************************************************

---   #HEADER // TOP NAVBAR
   
*************************************************
*/

#header {
    width: 100%;
    height: 72px;
    position: sticky;
    padding-left: 36px;
    box-sizing: border-box;  
    top: 0;
    left: 0;
    background: #1a6053;
    color: #fff;
    z-index: 999;
    transition: all ease-in-out 0.3s;
    font-family: Roboto;
}
#header.open{
    padding: 0 36px 0 111px;
    box-sizing: border-box;  
    transition: all 0.5s ease;
}
#header.shrink {
    height: 60px;
    background: rgb(72 29 126 / 0.81);
    -webkit-backdrop-filter: saturate(180%) blur(3px);
    backdrop-filter: saturate(180%) blur(3px);
}
.header-space {
  	height:72px;
}
#header .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

#header .header ul {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  padding-left: 0;
}
#header .header .logo {
  max-width: 210px;
  float: left;
}
#header .header .logo img {
  height: 70px;
  transition: all ease-in-out 0.3s;
}
#header.shrink .header .logo img {
  height: 70px;
  transition: all ease-in-out 0.3s;  
}
#header .header .menu {
  max-width: 210px;
  float: right;
}
#header .header .menu img {
  width: 36px;
  margin: 0 6px;
  transition: all ease-in-out 0.3s;  
}
#header .header .menu img:last-child {
  width: 27px;
  margin: 0 0 0 12px;
  transition: all ease-in-out 0.3s;  
}
#header.shrink .header .menu img {
  width: 27px;
  transition: all ease-in-out 0.3s;  
}


/*
*************************************************
:::   LANG  
*************************************************
*/

#lang {
  border-radius: 15px;
  text-align: left;
  width: 54px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 1;
  box-sizing: border-box;
}
#lang img {
  width: 36px;
  margin-right: 6px;
}
#lang .selected {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;
}
#lang .options {
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
  width: 54px;
  max-height: 250px;
  background: #a2d1ff;
  overflow: hidden;
  border-radius: 12px;
}
#lang .options a {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  box-sizing: border-box;
  background-image: none;
  color: #3e3e42;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 1;
  opacity: 0;
  transform-origin: bottom;
  margin-left: -3px;
}
#lang .options a:hover {
  background: #6dbcff;
  color: white;
}
#lang .options a:last-child {
  border-radius: 0 0 15px 15px;
}

#lang:hover,
#lang.hover {
  overflow: visible;
  border-radius: 15px 15px 0 0;
}
#lang:hover .options a,
#lang.hover .options a {
  animation: dropdown 0.2s ease forwards;
}
#lang:hover .options a:nth-child(1),
#lang.hover .options a:nth-child(1) {
  animation-duration: 0.2s;
  animation-delay: 0s;
}
#lang:hover .options a:nth-child(2),
#lang.hover .options a:nth-child(2) {
  animation-duration: 0.3s;
  animation-delay: 0.1s;
}
#lang:hover .options a:nth-child(3),
#lang.hover .options a:nth-child(3) {
  animation-duration: 0.4s;
  animation-delay: 0.2s;
}

/* ::::::::::::::::::::::::::: ANIMATIONS */

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0.98) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/*
-------------------------------------------------

---   MEDIA QUERIES   --->   #HEADER

-------------------------------------------------
*/

@media (max-width: 575px) {
#header {
    width: 100%;
    height: 66px;
    padding: 0 15px;
}
.header-space {
	height: 66px;
}  
#header .header .logo {
  max-width: 180px;
  float: left;
}
#header .header .logo img {
  width: 180px;
  transition: all ease-in-out 0.3s;
}
#header.shrink .header .logo img {
  width: 150px;
  transition: all ease-in-out 0.3s;
}
#header .header .menu {
  max-width: 210px;
  float: right;
}
#header .header .menu img {
  width: 36px;
  margin: 0 6px;
  transition: all ease-in-out 0.3s;  
}
#header .header .menu img:last-child {
  width: 30px;
  margin: 0 0 0 12px;
  transition: all ease-in-out 0.3s;
}
#header.shrink .header .menu img {
  width: 27px;
  transition: all ease-in-out 0.3s;  
}  
}

/*
*************************************************

---   #HEADER // TOP NAVBAR
   
*************************************************
*/

.main {
  max-width: 100%;
  padding: 36px;
  box-sizing: border-box;  
}



@media (min-width: 600px) and (max-width: 767px) {
.main {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 180px);  
}
.main img:nth-of-type(1) {
  grid-column: 1 / span 2;
}
.main img:nth-of-type(2) {
  grid-column: 3 / span 2;
}
.main img:nth-of-type(3) {
  grid-column: 1 / 1;
  grid-row: 2 / span 2;
}
.main img:nth-of-type(4) {
  grid-column: 2 / span 2;
  grid-row: 2 / 2;
}
.main img:nth-of-type(5) {
  grid-column: 2 / 2;
  grid-row: 3 / 3;
}
.main img:nth-of-type(6) {
  grid-column: 4 / 4;
  grid-row: 2 / 4;
}
.main img:nth-of-type(7) {
  grid-column: 3 / 3;
  grid-row: 3 / 3;
}
}

@media (min-width: 768px) {
.main {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 180px);  
}  
.main img:nth-of-type(2) {
  grid-column: 2 / span 2;
}
.main img:nth-of-type(3) {
  grid-column: 4 / 4;
  grid-row: 1 / span 3;
}
.main img:nth-of-type(4) {
  grid-column: 1 / 1;
  grid-row: 2 / span 2;
}
.main img:nth-of-type(5) {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
}
.main img:nth-of-type(6) {
  grid-column: 3 / 3;
  grid-row: 2 / 2;
}
.main img:nth-of-type(7) {
  grid-column: 2 / span 2;
  grid-row: 3 / 3;
}
}

.title_nav-list{
  color: white;
  font-family: 'Oswald', sans-serif !important;
  padding-left: 1rem;
}

.nav-list{
  overflow-y: scroll;
  padding-bottom: 10rem !important;
}