.stepperContainer{
  min-height: 40vh;
}

.step{
  border: 1px solid rgba(0, 0, 0, 0.17);
  width: 200px;
  font-size:15px
}

.activeStep{
  background-color: #008f58;
  color: white;
  border: none;
}



.step-box{
  border: 1.5px solid rgba(0, 0, 0, 0.17);
  min-height: 450px;
}

.step-number{
  display: none;
}

.btn-step-back, .btn-step-next{
  width: fit-content;
  padding-left: 2%;
  padding-right: 2%;
  height: 50px;
  border: none;
  font-size: 1.5rem;
  color: white;
}

.btn-step-back{
  background-color: rgb(73, 73, 73);

}

.btn-step-next{
  background-color: #004e64;
}

.btn-step-next-disabled{
  background-color: #555c5e89;
  color: white;
}

/* .rendu{
  min-height: 300px;
} */





/*================================================================================
              RESPONSIVE
  ==============================================================================*/
@media (max-width: 767.98px) {
  
  .step-number{
    display: block;
  }

  .step-title{
    display: none;
  }

  .btn-step-back, .btn-step-next{
    width: 70px;
    height: 40px;
    border: none;
    font-size: 1.3rem;
  }
}