@import './index.css';
@import './stepper.css';
@import './loginPage.css';

html{
    width: 100%;
    height: 100%;
}

#root{
    width: 100%;
    height: 100%;
}