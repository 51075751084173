.btn-details{
  border: none;
  border-radius: 20px;
  margin: auto;
  color: white !important;
  background-color: #2c6930;
}

.btn-details:hover{
  background-color: #ffff;
  color: #2c6930 !important;
  transition: all 0.5ms;
}

.btn-details-demande{
  border: none;
  border-radius: 20px ;
  height: 50px;
  background-color: white;
  border: solid #e28301;
  color: #2c6930;
  /* box-shadow: 1px 3px 10px #f2c494; */
  margin: auto;
}

.btn-details-demande:hover{
  
}

.btn-details-demande{
  margin-top: 10px;
}

.btn-toggle{
  background-color:  #2c6930;
  /* color: #107533; */
  color: white;
}



/* ////////////////////////////////////////////////
    responsive
/////////////////////////////////////////////////*/

@media (max-width: 575.98px) { 

.sidebarResponsive{
  background-color: #2c6930;
  
}

.logoMenu, .btn-nav-name{
  display: none;
  /* visibility: hidden; */
}

.btn-toggle{
  width: 50px;
  /* padding: 0px ; */
  font-size: 2rem !important;
}

.btn-navigation, .btn-nav-home{
  color: aliceblue !important;
  font-size: 2rem !important;
}

.btn-nav-home{
  display: inline-block;
  margin-top: 30px;
}

.header{
  position: sticky;
  top: 0px;
  z-index: 30;
}

.contenu{
  height: 100vh;
  overflow-y: auto;
}

.btn-conteneur{
  flex-wrap: wrap !important;
}

.btn-detail-demande{
  margin-top: 10px;
}


}